import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { An_Error, Error_specific } from '../../../component/alert'
import axios from 'axios'

export default function UsageKeys({ setPages }) {
    const [dataKeys, setDataKeys] = useState({})
    const [bonding_key, setBonding_key] = useState()
    const [dropdown, setDropdown] = useState(false)
    const [lodingBtn, setLodingBtn] = useState(false)
    const token = sessionStorage.getItem('token')

    function handlerAddKeys(e, collection) {
        setDataKeys((prevDataKeys) => ({
            ...prevDataKeys,
            [collection]: {
                ...prevDataKeys[collection],
                [e.target.name]: e.target.value,
            },
        }));
    }

    async function handlerSaveKeys() {
        setLodingBtn(true)
        const allInputs = document.querySelectorAll('.inputsKeys');
        const allNotEmpty = Array.from(allInputs).every((input) => input.value.trim() !== '');
        if (!allNotEmpty) {
            Error_specific('All fields are required.');
            setLodingBtn(false)
            return;
        }
        if (!bonding_key) {
            Error_specific('Could you please provide or enter a join key to link the orders table with the products table?');
            return;
        }
        try {
            const data = { ...dataKeys }
            data.bonding_key = bonding_key
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/add-keys`, data, { headers: { 'x-access-token': token } });
            setLodingBtn(false)
            setPages(prevPages => ({
                ...prevPages,
                activeTab: 6
            }))
        } catch (error) {
            setLodingBtn(false)
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message);
            } else {
                An_Error();
            }
        }
    }

    return (
        <motion.div initial={{ x: -400, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 600, opacity: 0 }} className='mx-auto md:mt-10 px-5'>
            <h2 class="font-serif text-2xl font-semibold text-neutral-700 inline-flex dark:text-neutral-300">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-7 h-7">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                </svg>
                / Usage keys control
            </h2>
            <div className="flex flex-col md:flex-row md:space-x-12 space-y-12 md:space-y-0 mt-6 ">
                <div class="overflow-hidden rounded-md border bg-white dark:bg-neutral-900 dark:border-neutral-600 w-full">
                    <div class="bg-neutral-100 dark:bg-neutral-700 py-3">
                        <p class="text-center font-medium text-neutral-500 dark:text-neutral-200">set up key usage for <span className="font-extrabold text-xl">Orders</span>  data</p>
                    </div>
                    <div class="mt-4 mb-10 px-6 lg:px-14">
                        <div class="">
                            <p class="max-w-md text-center mx-auto text-xs text-neutral-500 dark:text-neutral-400">"Please provide your key in source orders for smooth analytics processing. When adding a key, make sure to respect the key defined in the data source."</p>
                        </div>
                        <div class="mx-auto mt-5 flex w-5/6 flex-col space-y-2 relative">

                            <div className="flex gap-4 flex-col md:flex-row">
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="id_o" name="id" onChange={(e) => handlerAddKeys(e, 'orders')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder=" " />
                                    <label for="id_o" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> Order ID </label>
                                </div>
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="Order_date" name="order_date" onChange={(e) => handlerAddKeys(e, 'orders')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder=" " />
                                    <label for="Order_date" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> Order Date </label>
                                </div>
                            </div>
                            <div className="flex gap-4 flex-col md:flex-row">
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="city" name="city" onChange={(e) => handlerAddKeys(e, 'orders')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder=" " />
                                    <label for="city" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> City </label>
                                </div>
                            </div>
                            <div className="flex gap-4 flex-col md:flex-row">
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="Quantity" name="quantity" onChange={(e) => handlerAddKeys(e, 'orders')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder="" />
                                    <label for="Quantity" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> Quantity </label>
                                </div>
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="Total_price" name="total_price" onChange={(e) => handlerAddKeys(e, 'orders')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder="" />
                                    <label for="Total_price" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> Total Price </label>
                                </div>
                            </div>
                            <div className="flex gap-4 flex-col md:flex-row">
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="full_name" name="full_name" onChange={(e) => handlerAddKeys(e, 'orders')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder="" />
                                    <label for="full_name" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> Client full Name </label>
                                </div>
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="Phone_number" name="phone_number" onChange={(e) => handlerAddKeys(e, 'orders')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder="" />
                                    <label for="Phone_number" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> Client Phone Number </label>
                                </div>
                            </div>
                            <div className="flex space-x-1 flex-col md:flex-row">
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="product_name" name="product_key" onChange={(e) => handlerAddKeys(e, 'orders')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder="" />
                                    <label for="product_name" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500">Product Key</label>
                                    <div onClick={() => setDropdown(!dropdown)} class="absolute top-2/4 right-2 grid h-5 w-5 -translate-y-2/4 place-items-center text-neutral-600 dark:text-neutral-300 cursor-pointer">
                                        {!bonding_key ?
                                            <svg id="Layer_1" data-name="Layer 1" fill="currentColor" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 107.55"><defs><style dangerouslySetInnerHTML={{ __html: ".cls-1{fill:#f44336;fill-rule:evenodd;}" }} /></defs><title>Product foreign key-unlinked</title><path d="M51,31.48a5.35,5.35,0,1,1-7.56-7.56L60.71,6.61c5-5,12-7,18.94-6.55A30.6,30.6,0,0,1,99,8.55a30.6,30.6,0,0,1,8.49,19.35,28,28,0,0,1-.28,6.28,33.11,33.11,0,0,0-9.71-1.44h-.88a17.59,17.59,0,0,0,.22-4.18,19.67,19.67,0,0,0-5.4-12.46A19.65,19.65,0,0,0,79,10.71c-4.07-.26-8,.79-10.71,3.46L51,31.48Zm12.75,4.8a5.34,5.34,0,0,1,7.56,7.55L43.83,71.28a5.35,5.35,0,1,1-7.56-7.56L63.72,36.28Zm-7.32,40A5.35,5.35,0,1,1,64,83.82L46.84,100.94c-5,5-12,7-18.94,6.55A30.6,30.6,0,0,1,8.55,99,30.6,30.6,0,0,1,.06,79.65c-.44-7,1.56-13.94,6.55-18.94L23.73,43.59a5.35,5.35,0,0,1,7.56,7.56L14.17,68.27C11.5,70.94,10.45,74.91,10.71,79a19.65,19.65,0,0,0,5.4,12.46,19.67,19.67,0,0,0,12.46,5.4c4.07.26,8-.79,10.71-3.46L56.4,76.26Z" /><path className="cls-1" d="M97.5,40.51A25.38,25.38,0,1,1,72.13,65.89,25.38,25.38,0,0,1,97.5,40.51ZM86.26,60.83c-.93-.92-1.68-1.5-.51-2.63l3.77-3.69c1.19-1.21,1.89-1.14,3,0l5.08,5.08,5-5.05c.92-.94,1.5-1.68,2.63-.52L109,57.8c1.21,1.19,1.15,1.89,0,3l-5.09,5.08L109,71c1.14,1.12,1.2,1.82,0,3l-3.69,3.78c-1.13,1.17-1.71.42-2.63-.52l-5-5-5.08,5.08c-1.12,1.14-1.82,1.21-3,0l-3.77-3.69c-1.17-1.13-.42-1.71.51-2.63l5.06-5-5.06-5.06Z" /></svg>
                                            :
                                            <svg id="Layer_1" data-name="Layer 1" fill="currentColor" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 107.55"><defs><style dangerouslySetInnerHTML={{ __html: ".cls-1{fill:#00a912;fill-rule:evenodd;}" }} /></defs><title>Product foreign key-linked</title><path d="M51,31.48a5.35,5.35,0,1,1-7.56-7.56L60.71,6.61c5-5,12-7,18.94-6.55A30.6,30.6,0,0,1,99,8.55a30.6,30.6,0,0,1,8.49,19.35,28,28,0,0,1-.28,6.28,33.11,33.11,0,0,0-9.71-1.44h-.88a17.59,17.59,0,0,0,.22-4.18,19.67,19.67,0,0,0-5.4-12.46A19.65,19.65,0,0,0,79,10.71c-4.07-.26-8,.79-10.71,3.46L51,31.48Zm12.75,4.8a5.34,5.34,0,0,1,7.56,7.55L43.83,71.28a5.35,5.35,0,1,1-7.56-7.56L63.72,36.28Zm-7.32,40A5.35,5.35,0,1,1,64,83.82L46.84,100.94c-5,5-12,7-18.94,6.55A30.6,30.6,0,0,1,8.55,99,30.6,30.6,0,0,1,.06,79.65c-.44-7,1.56-13.94,6.55-18.94L23.73,43.59a5.35,5.35,0,0,1,7.56,7.56L14.17,68.27C11.5,70.94,10.45,74.91,10.71,79a19.65,19.65,0,0,0,5.4,12.46,19.67,19.67,0,0,0,12.46,5.4c4.07.26,8-.79,10.71-3.46L56.4,76.26Z" /><path className="cls-1" d="M97.51,40.51A25.38,25.38,0,1,1,72.13,65.88,25.37,25.37,0,0,1,97.51,40.51ZM89.63,61.85l4.5,4.25,10.63-10.77c.88-.9,1.43-1.61,2.52-.5l3.52,3.61c1.16,1.15,1.1,1.82,0,2.88L96.14,75.75c-2.3,2.26-1.9,2.4-4.23.08L83.7,67.67a1,1,0,0,1,.1-1.58l4.09-4.24c.62-.66,1.11-.6,1.74,0Z" /></svg>
                                        }
                                    </div>
                                </div>
                                <div class="relative mt-2 w-full">
                                    <p className="text-xs text-yellow-700 font-meduim inline-flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2 my-auto">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                                        </svg>
                                        a unique Key for efficient product identification and access in a database.
                                    </p>
                                    {dropdown &&
                                        <div class={`absolute left-28 md:left-0 top-0 -mt-20 md:-mt-5 text-neutral-700 dark:text-neutral-300 w-34 z-40 shadow-lg bg-white dark:bg-neutral-700 ring-1 ring-black ring-opacity-5 space-y-1 border border-neutral-200 dark:border-neutral-700`}>
                                            <div onClick={() => { setBonding_key('id'); setDropdown(false) }} class={`block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-200 cursor-pointer ${bonding_key === 'id' ? 'border-l-[3px] border-orange-500' : 'hover:text-orange-500'}`}>Product ID</div>
                                            <div onClick={() => { setBonding_key('ref'); setDropdown(false) }} class={`block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-200  cursor-pointer ${bonding_key === 'ref' ? 'border-l-[3px] border-orange-500' : 'hover:text-orange-500'}`}>REF</div>
                                            <div onClick={() => { setBonding_key('product_name'); setDropdown(false) }} class={`block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-200  cursor-pointer ${bonding_key === 'product_name' ? 'border-l-[3px] border-orange-500' : 'hover:text-orange-500'}`}>Product Name</div>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="overflow-hidden rounded-md border bg-white dark:bg-neutral-900 dark:border-neutral-600 w-full">
                    <div class="bg-neutral-100 dark:bg-neutral-700 py-3">
                        <p class="text-center font-medium text-neutral-500 dark:text-neutral-200">set up key usage for <span className="font-extrabold text-xl">Products</span> data</p>
                    </div>
                    <div class="mt-4 mb-10 px-6 lg:px-14">
                        <div class="">
                            <p class="max-w-md text-center mx-auto text-xs text-neutral-500 dark:text-neutral-400">"Please specify your key in the source products to facilitate smooth transaction and linking between orders and products. When adding a key, ensure that it aligns with the key defined in the data source."</p>
                        </div>
                        <div class="mx-auto mt-5 flex w-5/6 flex-col space-y-2">
                            <div className="flex gap-4 flex-col md:flex-row">
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="id" name="id" onChange={(e) => handlerAddKeys(e, 'products')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder=" " />
                                    <label for="id" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500">Product ID </label>
                                </div>
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="ref" name="ref" onChange={(e) => handlerAddKeys(e, 'products')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder=" " />
                                    <label for="ref" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> REF </label>
                                </div>
                            </div>
                            <div className="flex gap-4 flex-col md:flex-row">
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="name" name="product_name" onChange={(e) => handlerAddKeys(e, 'products')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder=" " />
                                    <label for="name" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> Product Name </label>
                                </div>
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="stock" name="stock" onChange={(e) => handlerAddKeys(e, 'products')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder=" " />
                                    <label for="stock" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> Stock </label>
                                </div>
                            </div>
                            <div className="flex gap-4 flex-col md:flex-row">
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="price" name="price" onChange={(e) => handlerAddKeys(e, 'products')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder=" " />
                                    <label for="price" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> Price </label>
                                </div>
                                <div class="relative mt-2 w-full">
                                    <input type="text" id="Sell_price" name="sell_price" onChange={(e) => handlerAddKeys(e, 'products')} class="peer inputsKeys block w-full appearance-none rounded-lg border border-neutral-300 bg-transparent px-2.5 pr-3 pb-2.5 pt-4 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0 dark:text-white" placeholder=" " />
                                    <label for="Sell_price" class="absolute -top-2 left-1 z-10 scale-75 transform cursor-text select-none bg-white dark:text-white dark:bg-neutral-900 px-2 text-sm text-neutral-500"> Sell Price </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <button onClick={() => { !lodingBtn && handlerSaveKeys() }} class="my-2 mt-6 flex items-center w-full justify-center rounded-md bg-neutral-900 py-3 font-medium text-white">
                {lodingBtn ? 'Chargement...' : 'Save And Continue'}
                <svg xmlns="http://www.w3.org/2000/svg" class="ml-4 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
            </button>

        </motion.div>
    )
}
