// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.myChartDiv {
  width: 150px !important;
  height: 150px !important;
  margin: auto;
}


.zigzag {
  border-collapse: separate;
  border-spacing: 2px;
}

* {
  font-family: "Basier Square Regular" !important;
  scroll-behavior: smooth !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #FF5C00;
}
`, "",{"version":3,"sources":["webpack://./src/Dashboard/App.css"],"names":[],"mappings":";AACA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,YAAY;AACd;;;AAGA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,+CAA+C;EAC/C,kCAAkC;AACpC;;AAEA;EACE,UAAU;EACV,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["\n.myChartDiv {\n  width: 150px !important;\n  height: 150px !important;\n  margin: auto;\n}\n\n\n.zigzag {\n  border-collapse: separate;\n  border-spacing: 2px;\n}\n\n* {\n  font-family: \"Basier Square Regular\" !important;\n  scroll-behavior: smooth !important;\n}\n\n::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n  cursor: pointer;\n}\n\n::-webkit-scrollbar-track {\n  background: #f1f1f1;\n  border-radius: 25px;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #888;\n  border-radius: 25px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #FF5C00;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
