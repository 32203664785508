import React, { useRef, useState } from 'react'
import { An_Error, Error_specific } from '../../component/alert';
import axios from 'axios';

export default function ResetPassword({ setModalResetPassword }) {
  const [page, setPage] = useState(0)
  const [loadingBTN, setLoadingBTN] = useState(false)
  const [email, setEmail] = useState()
  const [code, setCode] = useState(Array(6).fill(''));
  const inputs = useRef([]);
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  async function sendEmailVerificationCode() {
    try {
      if (!email) {
        return Error_specific("Please provide your email address.")
      }
      setLoadingBTN(true)
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/send-email-verification-code/reset`, { email })
      setPage(1)
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error();
      }
    } finally {
      setLoadingBTN(false)
    }
  }

  const handleChange = (e, index) => {
    const { value } = e.target;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Move focus based on the input's content
    if (value.length === 1 && index < inputs.current.length - 1) {
      // Move focus to the next input if a character is entered
      inputs.current[index + 1].focus();
    } else if (value.length === 0 && index > 0) {
      // Move focus to the previous input if the current input is cleared
      inputs.current[index - 1].focus();
    }
  };

  async function verifyEmailCode() {
    try {
      const isFilled = code.every(c => c !== '');
      if (!isFilled) {
        return Error_specific("Please enter the verification code.")
      }
      setLoadingBTN(true)
      const verificationCode = code.join('')
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/verify-email-code`, { email, code: verificationCode })
      setPage(2)
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error();
      }
    } finally {
      setLoadingBTN(false)
    }
  }

  async function resetPassword() {
    try {
      if (!newPassword && confirmPassword && email) {
        return Error_specific("Please provide all required fields.")
      }
      setLoadingBTN(true)
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/reset-password`, { email, newPassword, confirmPassword })
      setPage(3)
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error();
      }
    } finally {
      setLoadingBTN(false)
    }
  }

  return (
    <div className='px-4 h-full flex'>
      <div className={`absolute left-0 top-0 bg-orange-200 h-1.5 
        ${page === 0 ? 'w-1/4' : page === 1 ? 'w-2/4' : page === 2 ? 'w-3/4' : 'w-full'} 
        rounded-r-3xl`}></div>

      {page === 0 &&
        <div className='md:w-2/5 h-5/6 mt-6 mx-auto flex flex-col gap-5 justify-center'>
          <h1 className='text-left text-4xl font-bold text-neutral-800'>Forgot your password?</h1>
          <p className='text-left text-2xl font-normal text-neutral-500'>
            Enter the email address associated with your account and we'll send
            you a link to reset your password.
          </p>

          <div class="w-full relative mt-3 w-20">
            <input type="email" onChange={(e) => setEmail(e.target.value)} onKeyPress={(event) => {
              if (event.key === 'Enter' && !loadingBTN) {
                sendEmailVerificationCode();
              }
            }} value={email} class="peer block w-full appearance-none rounded-lg border-2 border-neutral-300 bg-transparent px-2.5 pr-3 pb-3 pt-8 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0" placeholder="test@test.com" />
            <label class="absolute top-2.5 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none text-md bg-[#F9F6F3] px-2 text-sm text-neutral-500 font-semibold"> Email </label>
          </div>
          <p className='text-left text-sm font-normal text-neutral-500'>
            If your email address exists in our database, and you haven't requested a password reset in the last
            30 minutes, you will receive a password recovery code at your email address in a few minutes.
          </p>
          <div className='mt-3 flex gap-10 items-center'>
            <button onClick={() => { !loadingBTN && sendEmailVerificationCode() }} className='w-max bg-orange-200/60 px-8 py-4 rounded-full text-neutral-800 text-lg font-bold focus:outline-none hover:bg-orange-600 hover:text-white transition-all duration-700'>
              {loadingBTN ? <div class="flex items-center justify-center ">
                <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div class="ml-2"> Processing.... </div>
              </div> : "Send code"}
            </button>
            <div className='flex gap-2 items-center'>
              <div className='bg-neutral-200/70 rounded-lg p-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499" />
                </svg>
              </div>
              <h1 className='text-lg font-bold text-neutral-500'>or Press Enter</h1>
            </div>
          </div>
        </div>}

      {page === 1 &&
        <div className='md:w-2/5 h-5/6 mt-6 mx-auto flex flex-col gap-5 justify-center'>
          <div onClick={() => { !loadingBTN && setPage(0) }} className='flex gap-3 items-center text-neutral-500 hover:text-neutral-700 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
            </svg>
            <h1 className='text-xl font-bold underline'>Back</h1>
          </div>
          <h1 className='mt-2 text-left text-4xl font-bold text-neutral-800'>Password reset</h1>
          <p className='text-left text-2xl font-normal text-neutral-500'>
            We sent a code to hey@patrickono.com.
          </p>
          <div className="flex items-center gap-2" autoComplete="off" id="validate">
            {Array.from({ length: 3 }).map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="inline-flex items-center justify-center bg-transparent border-2 border-neutral-300 rounded-md w-14 h-12 md:w-16 md:h-14 text-xl font-bold text-center focus:ring-0 focus:outline-none focus:border-orange-600 text-neutral-700"
                ref={el => inputs.current[index] = el}
                value={code[index]}
                onChange={(e) => handleChange(e, index)}
              />
            ))}
            <div className='text-xl px-2'>-</div>
            {Array.from({ length: 3 }).map((_, index) => (
              <input
                key={index + 3}
                type="text"
                maxLength="1"
                className="inline-flex items-center justify-center bg-transparent border-2 border-neutral-300 rounded-md w-14 h-12 md:w-16 md:h-14 text-xl font-bold text-center focus:ring-0 focus:outline-none focus:border-orange-600 text-neutral-700"
                ref={el => inputs.current[index + 3] = el}
                value={code[index + 3]}
                onChange={(e) => handleChange(e, index + 3)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter' && !loadingBTN && index === 2) {
                    verifyEmailCode();
                  }
                }}
              />
            ))}
          </div>


          <p className='text-left text-sm font-normal text-neutral-500'>
            Didn't receive the email? <span className='underline cursor-pointer hover:text-neutral-700'>Click to resend</span>.
          </p>
          <div className='mt-3 flex gap-10 items-center'>
            <button onClick={() => { !loadingBTN && verifyEmailCode() }} className='w-max bg-orange-200/60 px-8 py-4 rounded-full text-neutral-800 text-lg font-bold focus:outline-none hover:bg-orange-600 hover:text-white transition-all duration-700'>
              {loadingBTN ? <div class="flex items-center justify-center ">
                <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div class="ml-2"> Processing.... </div>
              </div> : "Submit"}
            </button>
            <div className='flex gap-2 items-center'>
              <div className='bg-neutral-200/70 rounded-lg p-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499" />
                </svg>
              </div>
              <h1 className='text-lg font-bold text-neutral-500'>or Press Enter</h1>
            </div>
          </div>
        </div>}

      {page === 2 &&
        <div className='md:w-2/5 h-5/6 mt-6 mx-auto flex flex-col gap-5 justify-center'>
          <div onClick={() => { !loadingBTN && setPage(1) }} className='flex gap-3 items-center text-neutral-500 hover:text-neutral-700 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
            </svg>
            <h1 className='text-xl font-bold underline'>Back</h1>
          </div>
          <h1 className='text-left text-4xl font-bold text-neutral-800'>Set new password</h1>
          <p className='text-left text-2xl font-normal text-neutral-500'>
            It must be at least 8 characters.
          </p>

          <div class="w-full relative mt-3 w-20">
            <input type={!showPassword ? 'password' : 'text'} onPaste={(event) => event.preventDefault()} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} class="block w-full appearance-none rounded-lg border-2 border-neutral-300 bg-transparent px-2.5 pr-3 pb-3 pt-8 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0" placeholder="********" />
            <label class="absolute top-2.5 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none text-md bg-[#F9F6F3] px-2 text-sm text-neutral-500 font-semibold"> Password  </label>
            <div onClick={() => setShowPassword(!showPassword)} class="absolute right-3 top-1/2 -translate-y-1/2 py-1 px-1 text-neutral-500 peer-focus:text-neutral-300 cursor-pointer hover:text-neutral-600">
              {showPassword ?
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>
                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                  <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clip-rule="evenodd" />
                </svg>}
            </div>
          </div>
          <div class="w-full relative w-20">
            <input type={!showPassword ? 'password' : 'text'} onPaste={(event) => event.preventDefault()} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === 'Enter' && !loadingBTN) {
                  resetPassword();
                }
              }}
              class="block w-full appearance-none rounded-lg border-2 border-neutral-300 bg-transparent px-2.5 pr-3 pb-3 pt-8 text-sm text-neutral-900 focus:border-2 focus:border-orange-600 focus:outline-none focus:ring-0" placeholder="********" />
            <label class="absolute top-2.5 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none text-md bg-[#F9F6F3] px-2 text-sm text-neutral-500 font-semibold"> Confirm password  </label>
            <div onClick={() => setShowPassword(!showPassword)} class="absolute right-3 top-1/2 -translate-y-1/2 py-1 px-1 text-neutral-500 peer-focus:text-neutral-300 cursor-pointer hover:text-neutral-600">
              {showPassword ?
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>
                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                  <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clip-rule="evenodd" />
                </svg>}
            </div>
          </div>

          <div className='mt-3 flex flex-col md:flex-row gap-6 md:gap-10 items-center'>
            <button onClick={() => { !loadingBTN && resetPassword() }} className='w-max bg-orange-200/60 px-8 py-4 rounded-full text-neutral-800 text-lg font-bold focus:outline-none hover:bg-orange-600 hover:text-white transition-all duration-700'>
              {loadingBTN ? <div class="flex items-center justify-center ">
                <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div class="ml-2"> Processing.... </div>
              </div> : "Reset password"}
            </button>
            <div className='flex gap-2 items-center'>
              <div className='bg-neutral-200/70 rounded-lg p-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499" />
                </svg>
              </div>
              <h1 className='text-lg font-bold text-neutral-500'>or Press Enter</h1>
            </div>
          </div>
        </div>}

      {page === 3 &&
        <div className='md:w-2/5 h-5/6 mt-6 mx-auto flex flex-col gap-5 justify-center'>
          <h1 className='text-center text-4xl font-bold text-neutral-800'>All done</h1>
          <p className='text-center text-2xl font-normal text-neutral-500'>
            Your password has been reset so now you can
            log in to your account.
          </p>

          <button onClick={() => setModalResetPassword(false)} className='mt-2 mx-auto w-max bg-orange-200/60 px-8 py-4 rounded-full text-neutral-800 text-lg font-bold focus:outline-none hover:bg-orange-600 hover:text-white transition-all duration-700'>
            Log In
          </button>
        </div>}
    </div>
  )
}
