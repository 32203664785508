import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react'
import { An_Error, Error_specific, Success } from '../../../../../component/alert';
import { DotSpinner } from '@uiball/loaders'

export default function Offers({ id, token }) {
    const [Offers, setOffers] = useState([])
    const [tempOffers, setTempOffers] = useState([])
    const [Loading, setLoading] = useState(true);
    const [LoadingBtn, setLoadingBtn] = useState(false);
    const [dropdownNewOffer, setDropdownNewOffer] = useState(false);
    const [DataNewOffer, setDataNewOffer] = useState({});
    const [toggleEdit, setToggleEdit] = useState(new Array(Offers.length).fill(false));

    const getOffers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/get-offers/${id}`, { headers: { 'x-access-token': token } });
            setOffers(response.data.offers);
            setTempOffers(response.data.offers);
        } catch (error) {
            An_Error()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getOffers()
    }, [])

    const handlerInputSChange = (key, value) => {
        setDataNewOffer({ ...DataNewOffer, [key]: value })
    }

    const handlerAddNewOffer = async () => {
        try {
            const allInputs = document.querySelectorAll('.inputs');
            const allNotEmpty = Array.from(allInputs).every((input) => input.value.trim() !== '');
            if (!allNotEmpty) {
                return Error_specific('All fields are required.');
            }
            setLoadingBtn(true)
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/products/add-new-offer/${id}`, DataNewOffer, { headers: { 'x-access-token': token } });
            Success(response.data.message); setDataNewOffer({}); setDropdownNewOffer(false)
            getOffers()
        } catch (error) {
            if (error.response.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        } finally {
            setLoadingBtn(false)
        }
    }

    const handlertoggleOffer = async (offerId, value) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/products/toggle-offer/${id}/${offerId}`, { value }, { headers: { 'x-access-token': token } });
            Success(response.data.message);
            getOffers()
        } catch (error) {
            if (error.response.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
    }

    const deleteOffer = async (offerId) => {
        try {
            setLoadingBtn(true)
            const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/products/delete-offer/${id}/${offerId}`, { headers: { 'x-access-token': token } });
            Success(response.data.message);
            getOffers()
        } catch (error) {
            if (error.response.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        } finally {
            setLoadingBtn(false)
        }
    }

    const handlerToggleEdit = (index) => {
        // Check if someone already has an offer open
        if (toggleEdit.some((isOpen, i) => isOpen && i !== index)) {
            Error_specific("Another offer is already being edited. Please close it before opening another one.");
            return;
        }
        // If no other offer is open, toggle the edit state of the current offer
        const temp = [...toggleEdit];
        temp[index] = !temp[index];
        setToggleEdit(temp);
    };

    const updateOffer = async (offerId) => {
        try {
            const allInputs = document.querySelectorAll('.editinputs');
            const allNotEmpty = Array.from(allInputs).every((input) => input.value.trim() !== '');
            if (!allNotEmpty) {
                return Error_specific('All fields are required.');
            }
            setLoadingBtn(true)
            const offer = Offers.find((i) => i.id === offerId);
            const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/products/update-offer/${id}/${offerId}`, offer, { headers: { 'x-access-token': token } });
            Success(response.data.message);
            setToggleEdit(new Array(Offers.length).fill(false));
            getOffers();
        } catch (error) {
            if (error.response.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        } finally {
            setLoadingBtn(false)
        }
    }

    const handleFilter = (searchFilter) => {
        const filteredData = tempOffers.filter((item) =>
        (item.price.toString().includes(searchFilter) ||
            item.quantity.toString().includes(searchFilter) ||
            item.description.toLowerCase().includes(searchFilter.toLowerCase()) ||
            item.isActive.toString().includes(searchFilter.toLowerCase())
        )
        );
        setOffers([...filteredData]);
    };

    return (
        <>
            <div className="flex flex-wrap gap-3 md:gap-6 items-center px-10 pt-7 md:pt-10">
                <h1 className="text-3xl font-bold text-neutral-800 dark:text-neutral-200">Offers</h1>
                <div class="relative flex flex-row w-2/7 items-center">
                    <svg class="absolute left-2 block h-5 w-5 m-2 text-neutral-700 dark:text-neutral-100" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <circle cx="11" cy="11" r="8" class=""></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65" class=""></line>
                    </svg>
                    <input type="name" onChange={(e) => handleFilter(e.target.value)} class="w-full py-2.5 pr-6 rounded-3xl bg-white dark:bg-neutral-700 dark:text-white border border-slate-300 pl-12 text-sm outline-none" placeholder={`Search`} />
                </div>
            </div>

            <div className='mt-2 md:mt-5 mb-5 py-2 md:py-5 px-10 h-[45vh] overflow-y-auto'>
                <div className='flex justify-between items-center'>
                    <select onChange={(e) => handleFilter(e.target.value)} class="md:w-max mt-auto rounded-3xl border border-slate-300 dark:border-neutral-600 px-4 py-1.5 text-sm text-black/90 font-semibold outline-none focus:border-slate-400 focus:ring-0 dark:bg-neutral-700 dark:text-neutral-200">
                        <option selected value="">All Statuses</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                    </select>
                    {!dropdownNewOffer && <button onClick={() => setDropdownNewOffer(true)} class="focus:ring-2 focus:ring-offset-2 focus:ring-neutral-700 mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-neutral-600 hover:bg-neutral-500 focus:outline-none rounded">
                        <p class="text-sm font-medium leading-none text-white">Make an Offer</p>
                    </button>}
                </div>
                <div className='mt-4 overflow-hidden'>
                    <AnimatePresence>
                        {dropdownNewOffer &&
                            <motion.div initial={{ y: -100, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -100, opacity: 0 }}
                                transition={{ duration: 0.4, ease: "easeOut" }} className='border border-slate-300 dark:border-neutral-700 rounded-lg'>
                                <div className="bg-neutral-50 dark:bg-neutral-800 px-8 py-3.5 text-sm font-normal text-left text-neutral-500 dark:text-neutral-400 uppercase flex justify-between items-center">
                                    <span>Add New Offer</span>
                                    <div className='flex gap-2'>
                                        <button title='cancel' onClick={() => { !LoadingBtn && setDropdownNewOffer(false) }} className='bg-white dark:bg-neutral-900 text-black/90 dark:text-neutral-100 p-1 border border-slate-300 dark:border-neutral-600 rounded-full hover:scale-110 transition duration-300'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                        <button title='save' onClick={() => { !LoadingBtn && handlerAddNewOffer(false) }} className='bg-white dark:bg-neutral-900 text-black/90 dark:text-neutral-100 p-1 border border-slate-300 dark:border-neutral-600 rounded-full hover:scale-110 transition duration-300'>
                                            {LoadingBtn ?
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 animate-spin">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                </svg>

                                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                </svg>}
                                        </button>
                                    </div>
                                </div>
                                <div className='bg-white dark:bg-neutral-900 px-8 py-4'>
                                    <div className='flex gap-2 md:gap-12'>
                                        <div className='w-full'>
                                            <label for="email" class="block text-sm font-medium dark:text-neutral-100">Quantity</label>
                                            <input type="number" onChange={(e) => handlerInputSChange("quantity", parseInt(e.target.value))}
                                                onKeyDown={(e) => { if (e.key === '-' || e.key === '.') { e.preventDefault() } }}
                                                class="w-full inputs rounded-md border border-neutral-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-orange-500 focus:ring-orange-500 dark:text-neutral-200 dark:bg-neutral-900 dark:border-neutral-600"
                                                placeholder="Enter quantity here" />
                                        </div>
                                        <div className='w-full'>
                                            <label for="email" class="block text-sm font-medium dark:text-neutral-100">Price</label>
                                            <input type="number" onChange={(e) => handlerInputSChange("price", parseFloat(e.target.value))} class="w-full inputs rounded-md border border-neutral-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-orange-500 focus:ring-orange-500 dark:text-neutral-200 dark:bg-neutral-900 dark:border-neutral-600"
                                                placeholder="Enter price here" />
                                        </div>
                                    </div>

                                    <div className='w-full mt-4'>
                                        <label for="email" class="mb-2 block text-sm font-medium dark:text-neutral-100">Description</label>
                                        <textarea cols={1} onChange={(e) => handlerInputSChange("description", e.target.value)} class="w-full inputs rounded-md border border-neutral-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-orange-500 focus:ring-orange-500 dark:text-neutral-200 dark:bg-neutral-900 dark:border-neutral-600"
                                            placeholder="Enter description here">
                                        </textarea>
                                    </div>
                                </div>
                            </motion.div>}
                    </AnimatePresence>
                </div>

                {Loading ? <div className='py-16 md:py-28 flex justify-center items-center'>
                    <DotSpinner
                        size={80}
                        speed={0.9}
                        color="gray"
                    />
                </div> : Offers.length > 0 ?
                    <div class="mt-4 overflow-hidden border border-slate-300 dark:border-neutral-700 rounded-lg overflow-x-auto">
                        <table className="min-w-full divide-y divide-slate-200 dark:divide-neutral-900">
                            <thead className="bg-neutral-50 dark:bg-neutral-800">
                                <tr>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left text-neutral-500 dark:text-neutral-400">
                                        Quantity
                                    </th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left text-neutral-500 dark:text-neutral-400">
                                        Price
                                    </th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left text-neutral-500 dark:text-neutral-400">
                                        Status
                                    </th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left text-neutral-500 dark:text-neutral-400">
                                        Description
                                    </th>
                                    <th scope="col" className="relative py-3.5 px-4">
                                        <span className="sr-only">Actions</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-neutral-200 dark:divide-neutral-700 dark:bg-neutral-900">
                                {Offers.map((offer, index) => (
                                    <tr key={index}>
                                        <td className="px-5 py-4 text-sm font-medium text-neutral-700 dark:text-neutral-200 whitespace-nowrap">
                                            <input type="number" value={offer?.quantity} disabled={!toggleEdit[index] && true}
                                                onKeyDown={(e) => { if (e.key === '-' || e.key === '.') { e.preventDefault() } }}
                                                onChange={(e) => setOffers(Offers.map((item, i) => (i === index ? { ...item, quantity: parseInt(e.target.value) } : item)))}
                                                className={`w-32 text-sm editInput bg-transparent text-neutral-500 dark:text-neutral-300 ${toggleEdit[index] ? "editinputs rounded-md border border-neutral-400 focus:border-orange-500 focus:ring-orange-500" : "border-none"}`}
                                                placeholder="Enter quantity here" />
                                        </td>
                                        <td className="px-4 py-4">
                                            <input type="number" value={offer?.price} disabled={!toggleEdit[index] && true}
                                                onChange={(e) => setOffers(Offers.map((item, i) => (i === index ? { ...item, price: parseFloat(e.target.value) } : item)))}
                                                className={`w-32 text-sm editInput bg-transparent text-neutral-500 dark:text-neutral-300 ${toggleEdit[index] ? "editinputs rounded-md border border-neutral-400 focus:border-orange-500 focus:ring-orange-500" : "border-none "}`}
                                                placeholder="Enter price here" />

                                        </td>
                                        <td className="px-4 py-4">
                                            <label for={`toggle-${offer.id}`} class="flex items-center cursor-pointer relative">
                                                <input type="checkbox" id={`toggle-${offer.id}`} class="sr-only"
                                                    onChange={() => handlertoggleOffer(offer.id, offer.isActive)}
                                                    checked={offer?.isActive || false} />
                                                <div class="toggle-bg bg-neutral-200 border-2 border-neutral-200 h-6 w-11 rounded-full"></div>
                                            </label>
                                        </td>
                                        <td className="px-4 py-4">
                                            <input type="text" value={offer?.description} onChange={(e) => setOffers(Offers.map((item, i) => (i === index ? { ...item, description: e.target.value } : item)))}
                                                disabled={!toggleEdit[index] && true} className={`w-full editinputs bg-transparent text-sm text-neutral-500 dark:text-neutral-300 ${toggleEdit[index] ? "rounded-md border border-neutral-400 focus:border-orange-500 focus:ring-orange-500" : "border-none "}`}
                                                placeholder="Enter description here" />
                                        </td>
                                        <td className="px-4 py-4 text-sm">
                                            <div className="flex items-center gap-x-6">
                                                {!toggleEdit[index] ? <>
                                                    <button onClick={() => { !LoadingBtn && handlerToggleEdit(index) }} className="text-neutral-500 transition-colors duration-200 dark:text-neutral-300 hover:text-blue-500 focus:outline-none hover:underline">
                                                        Edit
                                                    </button>
                                                    <button onClick={() => { !LoadingBtn && deleteOffer(offer.id) }} className="text-neutral-500 transition-colors duration-200 dark:text-neutral-300 hover:text-red-500 focus:outline-none hover:underline">
                                                        {LoadingBtn ? "Loading..." : "Delete"}
                                                    </button>
                                                </>
                                                    : <>
                                                        <button onClick={() => {
                                                            !LoadingBtn &&
                                                                getOffers();
                                                            setToggleEdit(new Array(Offers.length).fill(false))
                                                        }} className="text-neutral-500 transition-colors duration-200 dark:text-neutral-300 hover:text-neutral-800 focus:outline-none hover:underline">
                                                            Cancel
                                                        </button>
                                                        <button onClick={() => { !LoadingBtn && updateOffer(offer.id) }} className="text-neutral-500 transition-colors duration-200 dark:text-neutral-300 hover:text-blue-500 focus:outline-none hover:underline">
                                                            {LoadingBtn ? "Loading..." : "Save"}
                                                        </button>
                                                    </>}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    : <div className="animate-pulse py-16 md:py-28">
                        <p className="font-normal text-3xl mt-auto text-center text-neutral-400 dark:text-neutral-700 uppercase">There are currently no offers available to display...</p>
                    </div>}
            </div>
        </>
    )
}
