import React, { useState } from 'react'

const SectionsInfo = [
    {
        id: "account-setup",
        title: "How to set up an account on Selezy",
        description: "In this video, you'll learn how to quickly and easily set up an account on Selezy. Follow our step-by-step guide to get started and make the most of your new account.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "dashboard-overview",
        title: "Comprehensive Overview of the Dashboard System",
        description: "In this video, we provide a detailed exploration of the Dashboard System, covering its key features, user interface, and overall functionality. Learn how to navigate the system, understand its components, and utilize its tools to maximize your efficiency.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "user-creation",
        title: "How to create a user based on their role",
        description: "In this video, we walk you through the process of creating users tailored to specific roles within your system. Discover how to assign roles, manage permissions, and ensure that users have the appropriate access levels and capabilities based on their designated roles.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "product-creation",
        title: "How to create a product and manage all its associated functions",
        description: "This video guides you through the process of creating a product from scratch and managing its various functions. Learn how to set up product details, configure settings, and oversee related tasks such as inventory management, pricing adjustments, and updates. Ensure a smooth and efficient management of all aspects associated with your product.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "order-management",
        title: "How to receive and manage orders on the orders page",
        description: "In this video, we'll show you how to efficiently receive and manage orders on the Orders page. Learn how to view incoming orders, track their status, update order details, and handle various order management tasks to ensure a streamlined and effective process.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "delivery-management",
        title: "How to create and manage delivery users or companies, and handle stock management",
        description: "In this video, we cover the process of creating and managing delivery users or companies, as well as handling stock management. Discover how to set up delivery personnel or partner companies, assign tasks, and efficiently manage your inventory. Learn best practices for ensuring smooth operations and accurate stock control.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "transaction-management",
        title: "How to Create and Manage Transactions on the Payment Page",
        description: "In this video, we’ll guide you through the steps to create and manage transactions on the Payment page. Learn how to process payments, track transaction statuses, handle refunds, and manage payment records to ensure a seamless financial operation.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "deleted-items",
        title: "How to View and Manage All Deleted Items",
        description: "This video demonstrates how to view and manage all deleted items within your system. Learn how to access the deleted items archive, restore or permanently delete items, and ensure that your data management is efficient and well-organized.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "statistics-management",
        title: "How to Read and Handle Statistics",
        description: "In this video, we’ll explore how to read and interpret statistical data effectively. Learn how to analyze various statistics, understand their implications, and use this information to make informed decisions and improve performance.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "settings-management",
        title: "How to Edit Information and Manage Integrations",
        description: "This video provides a step-by-step guide on how to edit information and manage integrations within your system. Learn how to update and modify details, configure and oversee integrations with other tools or platforms, and ensure seamless connectivity and data flow across your system.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "confirmation-page",
        title: "How to Effectively Use the Confirmation Page to Verify, Track, and Manage Orders",
        description: "In this video, we’ll show you how to utilize the Confirmation Page to its fullest. Learn how to verify order details, track the progress of orders, and manage various aspects of order fulfillment to ensure accuracy and efficiency in your order processing system.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "delivery-tracking",
        title: "How to Track Orders for Delivery and Manage Dispatch Changes",
        description: "This video explains how to effectively track orders throughout the delivery process and manage any dispatch changes. Discover techniques for monitoring order status, handling delivery updates, and ensuring that dispatch modifications are smoothly integrated into your workflow for optimal order fulfillment.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    },
    {
        id: "payment-history",
        title: "How to Access and Utilize Payment History for Tracking and Managing Transactions",
        description: "In this video, we’ll guide you on how to access and effectively utilize your payment history. Learn how to track past transactions, review detailed payment records, and manage your financial data to ensure accurate and efficient transaction management.",
        videoUrl: "https://www.youtube.com/embed/dTIjAJO-tcQ?si=QNIR6mT8Vv4oFoL_"
    }
];

export default function Tutorial() {
    const [section, setSection] = useState(0)

    return (
        <div className='px-8 py-5 bg-neutral-50 select-none'>
            <div className='flex justify-between items-center'>
                <div className="max-w-3xl text-center">
                    <img src='/images/selezy logo complete orange.png' className='w-36 mx-auto' />
                </div>
                <a href='/' target='_blanc' className='px-2 md:px-5 py-2 md:py-3 border border-neutral-600 text-sm font-bold rounded-lg hover:bg-neutral-100 transition-all duration-400'>
                    Discover Selezy
                </a>
            </div>
            <div className='pt-10'>
                <div className='patern py-10 md:py-16 px-6'>
                    <div className='w-max px-2 py-1 text-xs font-extrabold border-[2px] border-neutral-900 text-neutral-900 rounded-2xl'>Support</div>
                    <h1 className='py-2 text-3xl md:text-6xl font-bold text-black/90 antialiased leading-tight'>How to use the Selezy solution?</h1>
                </div>

                <div className='mt-16'>
                    <h2 class="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug !mb-2 text-primary">Overview</h2>
                    <p class="block antialiased text-base font-normal leading-relaxed mb-4 mt-1 text-neutral-700 md:pr-16">
                        Selezy is an advanced software solution designed to optimize and automate the sales and marketing processes within businesses.
                        It features multiple user roles, including Super Admin, Admin, Sales Manager, and Sales Representative,
                        ensuring that each aspect of sales management is handled with precision. The platform provides a comprehensive suite of tools
                        for lead management, customer relationship management (CRM), and sales performance tracking. Selezy stands out with its intuitive interface,
                        customizable dashboards, and powerful analytics, enabling businesses to streamline their sales operations and enhance productivity.
                        With seamless integration capabilities, Selezy ensures a smooth and efficient sales workflow, empowering teams to focus on closing deals
                        and driving growth.
                    </p>
                    <h2 class="block mt-10 antialiased tracking-normal font-sans text-3xl font-semibold leading-snug !mb-2 text-primary">Browse by section</h2>
                    <div className='flex flex-wrap items-center gap-4 px-8 py-2'>
                        <div onClick={() => setSection(0)} className={`text-sm font-bold border border-black px-2.5 py-1.5 rounded-xl transition-all duration-400 ${section === 0 ? 'bg-black text-white' : 'text-black cursor-pointer hover:scale-105'}`}>
                            All (3)
                        </div>
                        <div onClick={() => setSection(1)} className={`text-sm font-bold border border-black px-2.5 py-1.5 rounded-xl transition-all duration-400 ${section === 1 ? 'bg-black text-white' : 'text-black cursor-pointer hover:scale-105'}`}>
                            General
                        </div>
                        <div onClick={() => setSection(2)} className={`text-sm font-bold border border-black px-2.5 py-1.5 rounded-xl transition-all duration-400 ${section === 2 ? 'bg-black text-white' : 'text-black cursor-pointer hover:scale-105'}`}>
                            Super Admin & Admin
                        </div>
                        <div onClick={() => setSection(3)} className={`text-sm font-bold border border-black px-2.5 py-1.5 rounded-xl transition-all duration-400 ${section === 3 ? 'bg-black text-white' : 'text-black cursor-pointer hover:scale-105'}`}>
                            Confirmer & Delivery
                        </div>
                    </div>
                    <ul className='list-disc py-4 px-7 flex flex-col gap-5'>
                        {section === 0 || section === 1 ? <>
                            <li href="#" className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#account-setup" className='block'>How to set up an account on Selezy</a>
                            </li>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#dashboard-overview" className='block'>Comprehensive Overview of the Dashboard System</a>
                            </li>
                        </> : null}
                        {section === 0 || section === 2 ? <>
                            <li className='md:w-max font-normal  bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#user-creation" className='block'>How to create a user based on their role</a>
                            </li>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#product-creation" className='block'>How to create a product and manage all its associated functions</a>
                            </li>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#order-management" className='block'>How to receive and manage orders on the orders page</a>
                            </li>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#delivery-management" className='block'>How to create and manage delivery users or companies, and handle stock management</a>
                            </li>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#transaction-management" className='block'>How to Create and Manage Transactions on the Payment Page</a>
                            </li>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#deleted-items" className='block'>How to View and Manage All Deleted Items</a>
                            </li>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#statistics-management" className='block'>How to Read and Handle Statistics</a>
                            </li>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#settings-management" className='block'>How to Edit Information and Manage Integrations</a>
                            </li>
                        </> : null}
                        {section === 0 || section === 3 ? <>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#confirmation-page" className='block'>How to Effectively Use the Confirmation Page to Verify, Track, and Manage Orders</a>
                            </li>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#delivery-tracking" className='block'>How to Track Orders for Delivery and Manage Dispatch Changes</a>
                            </li>
                            <li className='md:w-max font-normal text-sm md:text-lg bg-orange-100/80 border-b border-neutral-300 cursor-pointer text-neutral-700'>
                                <a href="#payment-history" className='block'>How to Access and Utilize Payment History for Tracking and Managing Transactions</a>
                            </li>
                        </> : null}

                    </ul>
                    {SectionsInfo.map(section => (
                        <div className='mt-8' id={section.id}>
                            <h2 className='block antialiased tracking-normal font-sans text-xl md:text-3xl font-semibold leading-snug !mb-2 text-primary flex gap-2 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" className='size-7'>
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5-3.9 19.5m-2.1-19.5-3.9 19.5" />
                                </svg>
                                {section.title}
                            </h2>
                            <p className='block antialiased text-base font-normal leading-relaxed mb-4 mt-1 text-neutral-700 md:pr-24'>
                                {section.description}
                            </p>
                            <iframe className='w-4/4 md:w-3/4 h-[40vh] md:h-[70vh]' src={section.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    ))}
                    <div className='mt-16 pt-10 border-t border-neutral-300'>
                        <h2 class="block antialiased tracking-normal font-sans text-3xl font-semibold leading-snug !mb-2 text-primary">Something Missing?</h2>
                        <p className='block antialiased text-base font-normal leading-relaxed mb-4 mt-1 text-neutral-700 md:pr-24'>
                            If you notice any missing information in the documentation or find any parts unclear, please let us know by filing an issue via <a href='mailto:selezybeta@gmail.com' className='underline text-black font-bold'>email</a> with your suggestions for improvement,
                            or reach out directly through the system chat. We appreciate your feedback and are always eager to hear from you.
                        </p>
                        <div className='mt-6 w-full bg-[#F1F4F5] py-14 shadow-xs rounded-xl flex flex-col gap-4 items-center justify-center'>
                            <div className='text-xl font-bold text-neutral-700'>Was this article helpful?</div>
                            <div className='flex gap-5'>
                                <a href="mailto:selezybeta@gmail.com?subject=Thank%20You%20for%20Providing%20Such%20a%20Helpful%20and%20Insightful%20Article" className='px-3 py-2 bg-neutral-50 border border-neutral-500 rounded flex gap-2 text-sm font-bold text-neutral-700 hover:bg-neutral-200 transation-all duration-300'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z" />
                                    </svg>
                                    Yes
                                </a>
                                <a href="mailto:selezybeta@gmail.com?subject=Request%20for%20Improvement%3A%20The%20Article%20Did%20Not%20Address%20the%20Issues%20Effectively" className='px-3 py-2 bg-neutral-50 border border-neutral-500 rounded flex gap-2 text-sm font-bold text-neutral-700 hover:bg-neutral-200 transation-all duration-300'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54" />
                                    </svg>
                                    No
                                </a>
                            </div>
                        </div>
                        <div className='mt-10 border-t border-neutral-200 px-2 py-4'>
                            <div className='text-xl font-normal uppercase'>Written by</div>
                            <div className='mt-4 flex gap-4 items-center'>
                                <img className='w-10 h-10 md:w-20 md:h-20 rounded-full' src='https://www.shutterstock.com/image-vector/blank-avatar-photo-place-holder-600nw-1095249842.jpg' alt='defauld' />
                                <div className='text-lg md:text-xl font-normal'>Mounir Rhrfani.</div>
                            </div>
                        </div>
                        <div className='w-full flex pt-1'>
                            <p class="mx-auto text-sm text-neutral-500">© Copyright 2024 Selezy. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
