import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { An_Error, Error_specific, Success } from "../../component/alert";


export default function Menu({ open, setOpen }) {
  const location = useLocation();
  const [orderOption, setOrderOption] = useState(false);
  const [userInfo, setUserInfo] = useState()
  const role = localStorage.getItem('role') || sessionStorage.getItem('role')
  const token = localStorage.getItem('token') || sessionStorage.getItem('token')
  const navigate = useNavigate()

  async function getData() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/get-userInfo`, { headers: { 'x-access-token': token } });
      setUserInfo(response.data.Data);
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error()
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  function removeItemsFromStorage(storage, ...keys) {
    keys.forEach(key => storage.removeItem(key));
  }

  async function logout() {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/logout`, null, { headers: { 'x-access-token': token, } });
      Success(response.data.message)
      removeItemsFromStorage(localStorage, 'token', 'role', 'loginDate');
      removeItemsFromStorage(sessionStorage, 'token', 'role', 'loginDate');
      window.location.href = "/login"
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message);
      } else {
        An_Error();
      }
    }
  }

  return (
    <div className="hidden md:block h-screen z-30 fixed top-0 buttom-0 " >
      <div
        className={` ${open ? "w-64" : "w-20 "
          } bg-white relative  p-5  pt-8 h-screen  duration-300 border-r-2 border-b-2 border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800 flex flex-col `}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          className="flex-grow"
        >
          <span
            className={`absolute cursor-pointer -right-4 top-4 w-9 h-9 z-50  border-neutral-200 dark:border-neutral-700 bg-white p-1 dark:bg-neutral-800 text-neutral-500  dark:text-neutral-300 
           border-2 rounded-full  ${!open && "rotate-180"}`}
            onClick={() => setOpen(!open)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
            </svg>

          </span>
          <div className="flex px-2 items-center mb-20 h-20">
            {open ? <>
              <img src='/images/selezy logo complete orange.png' className='w-44 dark:hidden' />
              <img src='/images/selezy logo complete white.png' className='w-44 hidden dark:block' />
            </> : <>
              <img src='/images/selezy logo fav orange.png' className='scale-125 dark:hidden' />
              <img src='/images/selezy logo fav white.png' className='scale-125 hidden dark:block' />
            </>}
          </div>
          <ul className="flex flex-col space-y-3 dark:text-neutral-300">
            <Link to="/dashboard"><li
              class={`text-sm w-full font-bold  py-2 px-2 cursor-pointer
                        rounded-lg transition duration-150 ease-in-out  ${location.pathname == "/dashboard" ? 'bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600' : "hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200 "}`}
            >
              <svg
                class="w-6 h-6  inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                ></path>
              </svg>
              <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                Dashboard
              </span>
            </li></Link>
            {role === 'Super_Admin' || role === 'admin' ?
              <>
                <div onClick={() => setOpen(true)}>
                  <li onClick={() => {
                    setOrderOption(open ? !orderOption : true);
                    !open && navigate("/dashboard/orders/confirmation")
                  }}
                    class={`text-sm w-full font-bold flex justify-between items-center  py-2 px-2   cursor-pointer
                        rounded-lg transition duration-150 ease-in-out  
                        ${(!orderOption || (orderOption && !open)) && (location.pathname == "/dashboard/orders/confirmation" || location.pathname == "/dashboard/orders/delivery"
                        || location.pathname == "/dashboard/orders/confirmation/add") ? "bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600" : "hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200"}
                        `}
                  >
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.8" stroke="currentColor" class="w-6 h-6  inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                      </svg>

                      <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                        Orders
                      </span>
                    </div>

                    <svg className={`w-4 h-4  ${!open && "hidden"} duration-200 ${orderOption && 'rotate-180'}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.0004 8.99963L12.0001 14.9999L5.99634 8.99609" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="my-path" />
                    </svg>


                  </li>
                  {orderOption && open ? <div className="flex flex-col gap-2  py-2  text-neutral-700 dark:text-neutral-200  hover:text-white transition duration-150 ease-in-out">
                    <Link to="/dashboard/orders/confirmation" className={`text-xs font-bold flex items-center justify-start gap-3 px-3 py-2.5 rounded-md  transition-all duration-300
                    ${location.pathname == "/dashboard/orders/confirmation" || location.pathname == "/dashboard/orders/confirmation/add" ?
                        'text-orange-600 bg-orange-100 dark:bg-orange-600 dark:text-white' : 'text-neutral-700 dark:text-neutral-200 hover:bg-neutral-100 dark:hover:bg-white/20 dark:hover:text-white'}`}>
                      <svg width={16} height={16} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.00378 5.99561L15.004 11.9959L9.00024 17.9996" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="my-path" />
                      </svg>
                      <span>Confirmation Orders</span>
                    </Link>
                    <Link to="/dashboard/orders/delivery" className={`text-xs font-bold flex items-center justify-start gap-3 px-3 py-2.5 rounded-md  transition-all duration-300
                    ${location.pathname == "/dashboard/orders/delivery" ?
                        'text-orange-600 bg-orange-100 dark:bg-orange-600 dark:text-white' : 'text-neutral-700 dark:text-neutral-200 hover:bg-neutral-100 dark:hover:bg-white/20 dark:hover:text-white'}`}>

                      <svg width={16} height={16} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.00378 5.99561L15.004 11.9959L9.00024 17.9996" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="my-path" />
                      </svg>

                      <span>Delivery Orders</span>
                    </Link>
                  </div> : null}
                </div>
                <Link to="/dashboard/products"><li
                  class={`text-sm w-full font-bold  py-2 px-2 cursor-pointer
                        rounded-lg transition duration-150 ease-in-out ${location.pathname == "/dashboard/products" || location.pathname == "/dashboard/products/add" || location.pathname.includes("/dashboard/products/Landingpage") ? 'bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600' : 'hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200 '}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.8" stroke="currentColor" class="w-6 h-6 inline-block" >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                  </svg>
                  <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                    Products
                  </span>
                </li></Link>
                <Link to="/dashboard/users"><li
                  class={`text-sm w-full font-bold  py-2 px-2  cursor-pointer
                        rounded-lg transition duration-150 ease-in-out  ${location.pathname == "/dashboard/users" ? 'bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600' : 'hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200 '}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.8" stroke="currentColor" class="w-6 h-6 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                  </svg>
                  <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                    User Management
                  </span>
                </li></Link>
                <Link to="/dashboard/delivery"><li
                  class={`text-sm w-full font-bold  py-2 px-2  cursor-pointer
                        rounded-lg transition duration-150 ease-in-out  ${location.pathname == "/dashboard/delivery" || location.pathname.includes("/dashboard/delivery/stock") ? 'bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600' : 'hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200 '}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                  </svg>

                  <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                    Delivery Management
                  </span>
                </li></Link>
                <Link to="/dashboard/payment"><li
                  class={`text-sm w-full font-bold  py-2 px-2  cursor-pointer
                        rounded-lg transition duration-150 ease-in-out  ${location.pathname == "/dashboard/payment" || location.pathname == '/dashboard/payment/generate-commission' ? 'bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600' : 'hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200 '}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>

                  <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                    Payment Management
                  </span>
                </li></Link>
                {role === "Super_Admin" && <Link to="/dashboard/trash"><li
                  class={`text-sm w-full font-bold  py-2 px-2  cursor-pointer
                        rounded-lg transition duration-150 ease-in-out  ${location.pathname == "/dashboard/trash" ? 'bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600' : 'hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200 '}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                  </svg>


                  <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                    Deleted Items Control
                  </span>
                </li></Link>}
              </>
              : ""}
            {role === 'confirmer' &&
              <>
                <Link to="/dashboard/confirmer/orders"><li
                  class={`text-sm w-full font-bold  py-2 px-2 hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200 cursor-pointer
                        rounded-lg transition duration-150 ease-in-out  ${location.pathname == "/dashboard/confirmer/orders" ? 'bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600' : ''}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.8" stroke="currentColor" class="w-6 h-6  inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                  </svg>
                  <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                    Orders
                  </span>
                </li></Link>
                <Link to="/dashboard/confirmer/payment"><li
                  class={`text-sm w-full font-bold  py-2 px-2  cursor-pointer
                        rounded-lg transition duration-150 ease-in-out  ${location.pathname == "/dashboard/confirmer/payment" ? 'bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600' : 'hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200 '}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>

                  <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                    Payment Management
                  </span>
                </li></Link>

              </>
            }
            {role === 'Delivery_User' ?
              <>
                <Link to="/dashboard/delivery/orders"><li
                  class={`text-sm w-full font-bold  py-2 px-2 hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200 cursor-pointer
                        rounded-lg transition duration-150 ease-in-out  ${location.pathname == "/dashboard/delivery/orders" ? 'bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600' : ''}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.8" stroke="currentColor" class="w-6 h-6  inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                  </svg>
                  <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                    Orders
                  </span>
                </li></Link>
                <Link to="/dashboard/delivery/payment"><li
                  class={`text-sm w-full font-bold  py-2 px-2  cursor-pointer
                        rounded-lg transition duration-150 ease-in-out  ${location.pathname == "/dashboard/delivery/payment" || location.pathname == '/dashboard/delivery/payment/generate-commission' ? 'bg-orange-100/50 text-orange-500 dark:text-white dark:bg-orange-600' : 'hover:bg-orange-600 hover:text-white text-neutral-700 dark:text-neutral-200 '}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>

                  <span className={`${!open && "hidden"} origin-left duration-200 ml-4`}>
                    Payment Management
                  </span>
                </li></Link>
              </>
              : null}
          </ul>
        </div>
        <Link to="/dashboard/settings">
          <button type="button" class={`relative ${!open ? 'w-[3.2em] px-3 -ml-1' : 'w-full px-6'}  inline-flex mb-5 cursor-pointer items-center rounded-2xl shadow-xl  bg-orange-600 border border-neutral-200 dark:border-neutral-700 py-3 text-center text-md tracking-widest font-bold text-white  hover:scale-105 focus:outline-none focus:shadow `}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.8" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span className={`${!open && "hidden"} ml-5 origin-left duration-200 `}>
              Settings
            </span>
          </button></Link>
        <div class={`flex justify-center w-full border-t border-neutral-300`}>
          <div
            class={`text-sm w-full font-extrabold cursor-pointer mt-4
                        rounded-lg transition duration-150 ease-in-out `}
          >
            <a href="#">
              <img class="inline-block rounded-full h-9 w-9"
                src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${userInfo?.companyName.replace(/\s/g, '_')}/${userInfo?.image}`}
                onError={(e) => e.target.src = "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"}
                alt="avatar" />
            </a>
            <span className={`${!open && "hidden "} ml-4 text-sm font-bold text-neutral-900 dark:text-neutral-200`}>
              {userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : 'unknown'}
            </span>
            <div onClick={() => logout()} class={`text-neutral-500 mt-2 cursor-pointer dark:text-neutral-200 transition-colors duration-200 rotate-180 dark:text-neutral-400 rtl:rotate-0 hover:text-orange-600 inline-block float-right ${!open && 'mr-3 mt-6'}`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}