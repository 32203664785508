import Papa from 'papaparse';
import { saveAs } from 'file-saver';


export const exportToCsv = (data) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

    const now = new Date();
    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear().toString();
    const filename = `data_${day}_${month}_${year}.csv`;

    saveAs(blob, filename);
};