import axios from "axios";
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { v4 } from 'uuid'
import { An_Error, Error_specific, Success } from "../../../../component/alert";
import { motion } from "framer-motion";



export default function AddNewManuallyProduct() {
    const [labels, setLabels] = useState([]);
    const [InputValidate, setInputValidate] = useState({});
    const navigate = useNavigate()
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')
    const [data, setData] = useState({});
    const [keys, setKeys] = useState([])

    async function get_keys_Data() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/get-product-keys`, { headers: { 'x-access-token': token } });
            setKeys(response.data.keys)
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
    }


    useEffect(() => {
        get_keys_Data()
    }, [])

    function AddNewLabel() {
        setLabels([...labels, v4()])
    }
    const removeLabel = (item) => {
        const updatedLabels = labels.filter((i) => i !== item);
        setLabels(updatedLabels);
    };

    function handlerInputs(e) {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    async function handlerSave() {
        let allInputsValidation = true;
        labels.forEach((item) => {
            let key = document.getElementById(`labelOf_${item}`).value;
            let val = document.getElementById(`valueOf_${item}`).value;
            if (key === '' || val === '') {
                allInputsValidation = false;
                setInputValidate({
                    ...InputValidate,
                    'ErrorOf_Advanced_data': 'All fields are required. Both key and value fields are required.'
                });
            }
        });

        const allInputs = document.querySelectorAll('.inputs');
        const allNotEmpty = Array.from(allInputs).every((input) => input.value.trim() !== '');
        if (!allNotEmpty) {
            setInputValidate({
                ...InputValidate,
                'ErrorOf_basics_data': 'All fields are required.'
            });
        }

        if (allInputsValidation && allNotEmpty) {
            setInputValidate({});
            const updatedData = { ...data };
            labels.forEach((item) => {
                let key = document.getElementById(`labelOf_${item}`).value;
                let val = document.getElementById(`valueOf_${item}`).value;
                updatedData[key] = val;
            });

            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/products/add-manualy`, updatedData, { headers: { 'x-access-token': token } });
                Success(response.data.message); navigate('/dashboard/products');
            } catch (error) {
                if (error.response?.status === 401) {
                    Error_specific(error.response.data.message)
                } else {
                    An_Error()
                }
            }
        }
    }


    return (
        <motion.main initial={{ x: 400, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 400, opacity: 0 }} class="pb-16 mb-4 ml-3 mr-3 mt-8 ">
            <div className="mt-5 bg-white rounded-lg shadow dark:bg-neutral-800 ">
                <div className="flex">
                    <div className="flex-1 py-5 pl-5 overflow-hidden dark:text-neutral-200">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-8 h-8 inline-flex mb-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        </svg>

                        <h1 className="inline text-2xl font-semibold leading-none ">basic product data</h1>
                    </div>
                </div>
                <div className="px-5 pb-5">
                    <div className="flex">
                        <div className="flex-grow w-1/4 pr-2">
                            <input placeholder={keys.id} name={keys.id} value={data[keys.id]} onChange={(e) => handlerInputs(e)} className="inputs text-black placeholder-neutral-600 w-full px-4 py-2.5 mt-2 text-base   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-neutral-200  focus:border-blueGray-500 focus:bg-white dark:focus:bg-neutral-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-neutral-400" />
                        </div>
                        <div className="flex-grow">
                            <button onClick={() => setData({ ...data, [keys.id]: v4() })} class="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 mt-4 ease-linear transition-all duration-150" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2 inline-flex">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
                                </svg>
                                Generate ID
                            </button>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex-grow w-1/4 pr-2">
                            <input onChange={(e) => handlerInputs(e)} placeholder={keys.ref} name={keys.ref} className="inputs text-black placeholder-neutral-600 w-full px-4 py-2.5 mt-2 text-base   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-neutral-200  focus:border-blueGray-500 focus:bg-white dark:focus:bg-neutral-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-neutral-400" />
                        </div>

                    </div>


                    <div className="flex">
                        <div className="flex-grow w-1/4 pr-2">
                            <input placeholder={keys.product_name} name={keys.product_name} onChange={(e) => handlerInputs(e)} type="text" className="inputs text-black placeholder-neutral-600 w-full px-4 py-2.5 mt-2 text-base   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-neutral-200  focus:border-blueGray-500 focus:bg-white dark:focus:bg-neutral-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-neutral-400" />
                        </div>
                        <div className="flex-grow">
                            <input placeholder={keys.stock} name={keys.stock} onChange={(e) => handlerInputs(e)} className="inputs text-black placeholder-neutral-600 w-full px-4 py-2.5 mt-2 text-base   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-neutral-200  focus:border-blueGray-500 focus:bg-white dark:focus:bg-neutral-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-neutral-400" />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex-grow w-1/4 pr-2">
                            <input placeholder={keys.price} name={keys.price} onChange={(e) => handlerInputs(e)} type="text" className="inputs text-black placeholder-neutral-600 w-full px-4 py-2.5 mt-2 text-base   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-neutral-200  focus:border-blueGray-500 focus:bg-white dark:focus:bg-neutral-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-neutral-400" />
                        </div>
                        <div className="flex-grow">
                            <input placeholder={keys.sell_price} name={keys.sell_price} onChange={(e) => handlerInputs(e)} className="inputs text-black placeholder-neutral-600 w-full px-4 py-2.5 mt-2 text-base   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-neutral-200  focus:border-blueGray-500 focus:bg-white dark:focus:bg-neutral-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-neutral-400" />
                        </div>
                    </div>
                    <p class="mt-2 text-lg text-red-600 dark:text-red-500">
                        {InputValidate.hasOwnProperty('ErrorOf_basics_data') ? InputValidate.ErrorOf_basics_data : ''}</p>


                </div>

                <div className="flex ml-1">
                    <div className="flex-1 py-5 pl-5 overflow-hidden dark:text-neutral-200">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-7 h-7 inline-flex mb-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
                        </svg>

                        <h1 className="inline text-2xl ml-1 font-semibold leading-none">Advanced order data</h1>
                    </div>

                </div>

                <div className="px-5 pb-5">
                    <button type="button" onClick={() => AddNewLabel()} className="relative w-full flex justify-center items-center px-5 py-2.5 font-medium tracking-wide text-white capitalize   bg-black rounded-md hover:bg-neutral-900  focus:outline-none   transition duration-300 transform active:scale-95 ease-in-out">
                        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
                            <g>
                                <rect fill="none" height={24} width={24} />
                            </g>
                            <g>
                                <g>
                                    <path d="M19,13h-6v6h-2v-6H5v-2h6V5h2v6h6V13z" />
                                </g>
                            </g>
                        </svg>
                        <span className="pl-2 mx-1">Create new product label</span>
                    </button>

                    {labels && labels.map((item) => {
                        return (
                            <div key={item} className="flex">
                                <div className="w-full"> <div className="grid grid-cols-2">
                                    <div className="flex-grow pr-2">
                                        <input placeholder="Label" id={`labelOf_${item}`} className="text-black placeholder-neutral-600 w-full px-4 py-2.5 mt-2 text-base   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-neutral-200  focus:border-blueGray-500 focus:bg-white dark:focus:bg-neutral-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-neutral-400" />
                                    </div>
                                    <div className="flex-grow">
                                        <input placeholder="Value of Label" id={`valueOf_${item}`} className="text-black placeholder-neutral-600 w-full px-4 py-2.5 mt-2 text-base   transition duration-500 ease-in-out transform border-transparent rounded-lg bg-neutral-200  focus:border-blueGray-500 focus:bg-white dark:focus:bg-neutral-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-neutral-400" />
                                    </div>

                                </div> </div>
                                <button onClick={() => removeLabel(item)} className=" text-red-500  mt-2 ml-4  hover:text-red-700 active:text-red-900 flex-grow">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>

                                </button>
                            </div>
                        )
                    })}
                    <p class="mt-2 text-sm text-red-600 dark:text-red-500">
                        {InputValidate.hasOwnProperty('ErrorOf_Advanced_data') ? InputValidate.ErrorOf_Advanced_data : ''}</p>



                </div>

                <hr className="mt-4" />
                <div className="flex flex-row-reverse p-3">
                    <div className="flex-initial pl-3">
                        <button onClick={() => handlerSave()} type="button" className="flex items-center px-5 py-2.5 font-medium tracking-wide text-white capitalize   bg-black  rounded-md hover:bg-neutral-800  focus:outline-none focus:bg-neutral-900  transition duration-300 transform active:scale-95 ease-in-out">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M5 5v14h14V7.83L16.17 5H5zm7 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-8H6V6h9v4z" opacity=".3" />
                                <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                            </svg>
                            <span className="pl-2 mx-1">Save</span>
                        </button>

                    </div>
                    <div className="flex-initial ">
                        <Link to={'/dashboard/products'}>
                            <button type="button" className="flex items-center px-5 py-2.5 font-medium tracking-wide text-black capitalize rounded-md  hover:bg-neutral-600 hover:text-white hover:fill-current   focus:outline-none  transition duration-300 transform active:scale-95 ease-in-out dark:text-neutral-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span className="pl-2 mx-1">Cancel</span>
                            </button></Link>
                    </div>
                </div>
            </div>


        </motion.main>
    )
}