import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { An_Error, Error_specific, Success } from '../../../../../component/alert';

export default function CreateSalaryPayment({ UserFilterType, setModalAddPayment, token, handlerReloadData }) {
    const [users, setUsers] = useState([])
    const [data, setData] = useState({})
    const [loadingBtn, setLoadingBtn] = useState(false)

    useEffect(() => {
        async function getData() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/payment/get-salary-confirmers&deliveryusers/${UserFilterType}`, { headers: { 'x-access-token': token } });
                setUsers(response.data.Data);
            } catch (error) {
                if (error.response?.status === 401) {
                    Error_specific(error.response.data.message)
                } else {
                    An_Error()
                }
            }
        }
        getData()
    }, [])


    function handlerInputsChange(name, value) {
        let updatedData = { ...data, [name]: value };

        if (name === 'transaction_to') {
            const user = users.find(i => i._id === value);
            if (user) {
                updatedData['amount'] = parseFloat(user.payAmount);
            }
        }

        setData(updatedData);
    }


    const createTransaction = async (e) => {
        const allInputs = document.querySelectorAll('.inputs');
        const allNotEmpty = Array.from(allInputs).every((input) => input.value.trim() !== '');
        if (!allNotEmpty) {
            Error_specific('Please fill in the required field.')
            return
        }
        try {
            setLoadingBtn(true)
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/payment/create-salary-transaction/${UserFilterType}`,
                data,
                { headers: { 'x-access-token': token } });

            Success(response.data.message);
            handlerReloadData();
            setModalAddPayment(false)
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        } finally {
            setLoadingBtn(false)
        }

    }

    return (
        <div class="col-span-8 bg-neutral-50 dark:bg-neutral-900 px-8 h-max mb-3 overflow-y-auto py-5" >
            <div class="relative text-neutral-700 dark:text-neutral-200">
                <label class="mt-3 mb-2 block text-sm font-medium float-left capitalize">{UserFilterType}</label>
                <select onChange={(e) => handlerInputsChange('transaction_to', e.target.value)} class="inputs w-full rounded-xl dark:bg-neutral-900 border border-neutral-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-[1px] focus:border-orange-500 focus:ring-orange-500">
                    <option selected disabled>please select {UserFilterType} user</option>
                    {users?.length > 0 && users.map((item, index) => (
                        <option key={index} value={item?._id} >{`${item?.firstName} ${item?.lastName}`}</option>
                    ))}
                </select>
            </div>
            <div class="mt-3 text-neutral-700 dark:text-neutral-200">
                <label class="mt-3 mb-2 block text-sm font-medium float-left capitalize">Amount</label>
                <input type="number" value={data?.amount} onChange={(e) => handlerInputsChange('amount', parseFloat(e.target.value))} placeholder="please enter the amount" class="inputs w-full rounded-xl dark:bg-neutral-900 border border-neutral-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-[1px] focus:border-orange-500 focus:ring-orange-500" />
            </div>
            <div class="mt-3 text-neutral-700 dark:text-neutral-200">
                <label class="mt-3 mb-2 block text-sm font-medium float-left capitalize">Notes <span className="text-xs">(not required)</span></label>
                <textarea placeholder="notes..." onChange={(e) => handlerInputsChange('notes', e.target.value)} class="w-full rounded-xl dark:bg-neutral-900 border border-neutral-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-[1px] focus:border-orange-500 focus:ring-orange-500" >
                </textarea>
            </div>
            <div class="mt-6 space-y-4">
                <button onClick={() => { !loadingBtn && createTransaction() }} class="p-3 bg-black rounded-full text-white w-full font-semibold">
                    {loadingBtn ? <div class="flex items-center justify-center ">
                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <div class="ml-2"> Processing.... </div>
                    </div> : "Process Salary Payment"}
                </button>
                <button onClick={() => setModalAddPayment(false)} class="p-3 bg-white border rounded-full w-full font-semibold">Cancel</button>
            </div>
        </div>
    )
}
