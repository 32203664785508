import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { An_Error, Error_specific, Success } from '../../../../../component/alert';
import { DotSpinner } from '@uiball/loaders'


export default function GetConfirmersDeliveries({ token, type, id, ProductID, qnt, setModalUsers, OrderId, handlerReloadOrder, multipleOrders = false, handlerExport = null, setDeliveryType = null }) {
    const [data, setData] = useState();
    const [tempData, setTempData] = useState();
    const [loading, setLoading] = useState(true);
    const [LoadingBtn, setLoadingBtn] = useState(false);
    const [selectedID, setSelectedID] = useState(id)

    useEffect(() => {
        async function getData() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/orders/get-confirmers-deliveries/${type}/${ProductID}/${qnt}`, { headers: { 'x-access-token': token } });
                setData(response.data.data);
                setTempData(response.data.data);
            } catch (error) {
                if (error.response?.status === 401) {
                    Error_specific(error.response.data.message)
                } else {
                    An_Error()
                }
            } finally {
                setLoading(false)
            }
        }
        getData()
    }, [])


    const handlerFilterSearch = (text) => {
        const lowercaseText = text.toLowerCase().replace(/\s/g, '');
        let filteredData = data.filter((item) =>
        (
            (item['firstName']?.toLowerCase() + item['lastName']?.toLowerCase()).includes(lowercaseText) ||
            item['email']?.toLowerCase()?.includes(lowercaseText)
        )
        );
        setTempData([...filteredData]);
    }

    const handleChangeConfirmerOrDeliveryId = async () => {
        if (!selectedID) {
            return Error_specific('Please ensure that you alter something before saving it!')
        }
        try {
            setLoadingBtn(true)
            const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/orders/change-confirmerOrdeliveries/${type}/${OrderId}`, { id: selectedID }, { headers: { 'x-access-token': token } });
            Success(response.data.message);
            handlerReloadOrder();
            setModalUsers(false)
        } catch (error) {
            An_Error()
        } finally {
            setLoadingBtn(false)
        }
    }

    const handleAssignConfirmerOrDeliveryId = async (exportData = false) => {
        if (!selectedID) {
            return Error_specific('Please ensure that you alter something before saving it!')
        }
        try {
            setLoadingBtn(true)
            const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/orders/assign-orders-to-confirmer&delivery/${selectedID}`,
                { checkedIds: OrderId }, { headers: { 'x-access-token': token } });
            Success(response.data.message);
            if (exportData) {
                handlerExport()
            } else {
                setDeliveryType('delivered')
                handlerReloadOrder();
            }
            setModalUsers(false);
        } catch (error) {
            An_Error()
        } finally {
            setLoadingBtn(false)
        }
    }

    return (
        <div>
            <div className="mt-5 relative">
                <input onChange={(e) => handlerFilterSearch(e.target.value)} className="px-4 w-full py-1.5 bg-neutral-50 dark:bg-neutral-900 border border-neutral-300 dark:border-neutral-600 rounded text-sm text-neutral-900 dark:text-neutral-100 font-normal outline-none focus-within:border-orange-600 focus-within:ring focus-within:ring-orange-600 dark:focus-within:border-orange-600 focus-within:ring-opacity-20" placeholder="search by full name, email" />
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5 h-5 text-neutral-700 dark:text-neutral-200 absolute top-2 right-3">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
            </div>
            <h2 className="mt-4 font-light text-neutral-500">Existing Active {type === "confirmation" ? "Confirmers" : "Delivery Users & Companies"}</h2>
            <div className="mt-2 flex flex-col gap-3 pr-2 max-h-[40vh] overflow-y-auto">
                {loading ? <div className='flex items-center justify-center py-10'>
                    <DotSpinner
                        size={40}
                        speed={0.9}
                        color="gray"
                    />
                </div> :
                    tempData?.length > 0 ? tempData.map((item, index) => (
                        <div key={index} class="flex justify-between items-center transition-all duration-300">
                            <div class="flex items-center">
                                <div class="flex-shrink-0 w-8 h-8">
                                    <img loading="lazy" class="w-full h-full rounded-full"
                                        src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${item?.companyName?.replace(/\s/g, '_')}/${item?.image}`}
                                        onError={(e) => e.target.src = "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"}
                                        alt="avatar" />
                                </div>
                                <div class="ml-3">
                                    <p class="text-sm text-neutral-900 dark:text-neutral-200 whitespace-no-wrap">
                                        {`${item?.firstName || "N/A"} ${item?.lastName || ''}`}
                                    </p>
                                    <p class="text-xs text-neutral-600 dark:text-neutral-400 whitespace-no-wrap">{item?.email || "N/A"}</p>
                                </div>
                            </div>
                            {item._id === selectedID ?
                                <div className="text-sm text-neutral-800 dark:text-neutral-100 px-1">
                                    Selected
                                </div>
                                : <div onClick={() => { !LoadingBtn && setSelectedID(item._id) }} className="border border-neutral-300 dark:border-neutral-600 text-sm text-neutral-700 dark:text-neutral-300 px-3 py-1.5 rounded cursor-pointer hover:scale-90 transition-all duration-300">
                                    Select
                                </div>}
                        </div>
                    ))
                        : <div className="text-neutral-700 col-span-full dark:text-neutral-400 animate-pulse py-10">
                            <p className="font-normal text-sm mt-auto text-center text-neutral-400 dark:text-neutral-700">There are no {type === "confirmation" ? "Confirmers" : "Delivery Users & Companies"} available for display....</p>
                        </div>
                }

            </div>

            <div class="flex flex-col md:flex-row justify-end items-center gap-3 mt-4 py-4 ">
                <button onClick={() => { !LoadingBtn && setModalUsers(false) }} type="button" class="w-full md:w-max py-3 px-4 text-sm font-medium tracking-wide text-neutral-700 capitalize transition-colors duration-300 transform border border-neutral-200 rounded-md dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800 hover:bg-neutral-100 focus:outline-none focus:ring focus:ring-neutral-300 focus:ring-opacity-40">
                    Cancel
                </button>

                <button onClick={() => {
                    if (!LoadingBtn) {
                        if (multipleOrders) {
                            handleAssignConfirmerOrDeliveryId();
                        } else {
                            handleChangeConfirmerOrDeliveryId();
                        }
                    }
                }}
                    class="w-full md:w-max py-3 px-4  text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-orange-600 rounded-md  hover:bg-orange-500 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40">
                    {LoadingBtn ? <div class="flex items-center justify-center ">
                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <div class="ml-2"> Processing.... </div>
                    </div> : "Confirm changes"}
                </button>
                {multipleOrders && <button onClick={() => {
                    if (!LoadingBtn) {
                        handleAssignConfirmerOrDeliveryId(true);
                    }
                }}
                    class="w-full md:w-max py-3 px-4  text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-orange-600 rounded-md  hover:bg-orange-500 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40">
                    {LoadingBtn ? <div class="flex items-center justify-center ">
                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <div class="ml-2"> Processing.... </div>
                    </div> : "Confirm changes & Export"}
                </button>}
            </div>
        </div>
    )
}
