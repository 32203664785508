import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { An_Error, Error_specific } from '../../../component/alert';
import axios from 'axios';

export default function Login({ setPages }) {
    const [loginData, setLoginData] = useState({})
    const [lodingBtn, setLodingBtn] = useState(false)

    // function for handle Session and localStorage clear
    function removeItemsFromStorage(storage, ...keys) {
        keys.forEach(key => storage.removeItem(key));
    }

    // Login Function and valid role
    async function Login() {
        setLodingBtn(true)
        const allInputs = document.querySelectorAll('.inputs');
        const allNotEmpty = Array.from(allInputs).every((input) => input.value.trim() !== '');
        if (!allNotEmpty) {
            Error_specific('All fields are required.');
            setLodingBtn(false)
            return;
        }
        try {
            // clear token befor Login
            removeItemsFromStorage(localStorage, 'token', 'user_id');
            removeItemsFromStorage(sessionStorage, 'token', 'user_id');
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/login`, loginData);
            if (response.status === 200 && response.data.role === 'Super_Admin') {
                // set usage variables to storage
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('role', response.data.role);
                const loginDate = new Date();
                sessionStorage.setItem('loginDate', loginDate);
                setPages(prevPages => ({
                    ...prevPages,
                    activeTab: 4
                }))
                setLodingBtn(false)
            } else {
                setLodingBtn(false)
                return Error_specific('Authentication failed!!');
            }
        } catch (error) {
            setLodingBtn(false)
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message);
            } else {
                An_Error();
            }
        }
    }
    return (
        <motion.div initial={{ x: -600, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 600, opacity: 0 }} class="flex w-full flex-col px-4">
            <div class="w-full mx-auto my-auto flex flex-col justify-center pt-8 md:justify-start md:px-6 md:pt-0">
                <p class="text-center text-7xl font-bold md:leading-tight md:text-left md:text-8xl text-neutral-700 dark:text-neutral-300">
                    Welcome <br />
                    to <span class="text-orange-600">Selezy</span>
                </p>
                <p class="mt-2 text-center font-medium md:text-left text-neutral-500 dark:text-neutral-400">Sign in to your account below.</p>


                <div class="flex flex-col pt-3 md:pt-8 md:w-2/4 mx-auto">
                    <div class="flex flex-col pt-4">
                        <div class="focus-within:border-b-neutral-500 relative flex overflow-hidden border-b-2 transition">
                            <input type="email" id="login-email" onChange={(e) => setLoginData({ ...loginData, 'email': e.target.value })} class="w-full inputs flex-1 appearance-none border-neutral-300 bg-white px-4 py-2 text-base text-neutral-700 dark:text-neutral-200 dark:bg-neutral-900 placeholder-neutral-400 focus:outline-none" placeholder="Enter your Email" />
                        </div>
                    </div>
                    <div class="mb-12 flex flex-col pt-4">
                        <div class="focus-within:border-b-neutral-500 relative flex overflow-hidden border-b-2 transition">
                            <input type="password" id="login-password" onChange={(e) => setLoginData({ ...loginData, 'password': e.target.value })} class="w-full flex-1 inputs appearance-none border-neutral-300 bg-white px-4 py-2 text-base text-neutral-700 dark:text-neutral-200 dark:bg-neutral-900 placeholder-neutral-400 focus:outline-none" placeholder="Password" />
                        </div>
                    </div>
                    <button onClick={() => { !lodingBtn && Login() }} type="submit" class="w-full py-3 px-6 font-medium tracking-widest text-white uppercase bg-black shadow-lg focus:outline-none hover:bg-neutral-900 hover:shadow-none">
                        {lodingBtn ? 'Chargement...' : 'Log In And Continue'}
                        <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:translate-x-2 ml-4 h-5 w-5 transition-transform inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                    </button>                            </div>
            </div>
        </motion.div>
    )
}
